$(document).ready(function () {
  // your code

  function scrollToEl(el){
    $("html, body").animate({
        scrollTop: $(el).offset().top - $(".main-header").outerHeight(),
      }, 500);
  }

  $("#asideNav").on("show.bs.modal", function (event) {
    $("body").addClass("menu-open");
  });
  $("#asideNav").on("hide.bs.modal", function (event) {
    $("body").removeClass("menu-open");
  });

  $(".scroll-to-el").on("click", function(e){
    e.preventDefault();
    scrollToEl($(this).attr("href"))
  })

  $(".sidenav .nav-link").on("click", function (e) {
    e.preventDefault();
    var el = $(this).attr("href");
    $(".nav-link").removeClass("active");
    $(this).addClass("active");
    scrollToEl(el);
  });

  $(window).on("scroll", function () {
    if ($(window).scrollTop() > $(window).height() / 2) {
      $(".header").addClass("active");
    } else {
      $(".header").removeClass("active");
    }
  });
});
